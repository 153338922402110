import {
  Component,
  Input,
  OnInit
} from "@angular/core";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {takeUntil} from "rxjs/operators";

import {Unsubscribe} from "../../../@shared/unsubscribe";
import {LanguageLogicService} from "../language-logic.service";
import {LANG} from "../lang.interface";



@Component({
  selector: 'app-select-lang',
  templateUrl: './select-lang.component.html',
  styleUrls: ['./select-lang.component.scss']
})
export class SelectLangComponent extends Unsubscribe implements OnInit {

  langs?: LANG[];
  @Input() formArrayNameCategories!: FormArray;
  @Input() form!: FormGroup;
  @Input() index!: number;
  @Input() formControl: FormControl;

  constructor(private languageLogicService: LanguageLogicService) {
    super();
  }

  ngOnInit(): void {
    this.getLang();
  }

  // get langControl(): FormControl {
  //   return this.form.get(['descriptions', this.index, 'lang']) as FormControl;
  // }

  getLang(): void {
    this.languageLogicService.getLang()
        .pipe(takeUntil(this.$destroy))
        .subscribe({
          next: value => this.langs = value.data
        });
  }

  getFilteredLanguages(index: number): LANG[] {
    return  this.languageLogicService.getFilteredLanguages(this.formArrayNameCategories, this.langs, index);
  }

}
