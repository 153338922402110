<div class="container-fluid">
  <div class="row">
    <div class="col-sm-4">

      <ng-container *ngIf="peService.loaderCity === false; else loading">
        <small id="countAllOrder" class="form-text text-muted">{{'GLOBAL.CITYCOUNT' | translate}}
          {{peService.arrayAllCity?.length}} </small>
        <div class="alert" role="alert" *ngFor="let object of peService.arrayAllCity; let i = index"
          (click)="getAllTechCards(object.id)"
          [ngClass]="{'alert-success': cityId==object.id, 'alert-light': cityId!=object.id}">
          <p>
          </p>
          <hr>
          <div class="d-flex justify-content-between align-middle">
            <p class="my-auto">{{'GLOBAL.CITY' | translate}}: {{object.name}}</p>
          </div>
          <hr>
          <p>ID: {{object.id}}</p>
        </div>
      </ng-container>
    </div>
    <div class="col-sm-8">


      <ng-container *ngIf="cityId">
        <ng-container *ngIf="peService.loaderTech === false; else loading">
          <div class="text-center mt-2">
            <label class="font-weight-bold ">{{'STORAGE.DELAYWARNINGFORPRICE' | translate}}</label>

          </div>

          <div class="form-group">
            <label >{{'STORAGE.FILTERTECHCARDSPRODUCT' | translate}}</label>
            <div>
                <app-search-input (searchChange)="peService.inputSearch = $event"> </app-search-input>
              <div class="d-flex mt-3">
                <button class="btn btn-success mr-2" (click)="excelExport('reportPrice')">{{'TECH_CARDS.DOWNLOAD_PRICE'
                        | translate}}</button>
                <button class="btn btn-success mr-2" (click)="addNewProductPrice(modal)">{{'GLOBAL.CREATE' |
                        translate}}</button>
                <button class="btn btn-success" (click)="filterPromotionalPrice()" >
                  {{ (showPromotionalPrice ? 'STORAGE.ALL_PRODUCTS' : 'STORAGE.PROMOTIONAL_PRODUCTS') | translate }}
                </button>
              </div>
            </div>
            <small id="countAllOrder" class="form-text text-muted">{{'STORAGE.COUNTTECHCARDS' | translate}}
              {{peService.arrayAllTechCards.length}}</small>
          </div>

          <table class="table">
            <thead>
              <th>
                {{'GLOBAL.TITLE' | translate}}
              </th>
              <th>
                {{'STORAGE.DISCOUNT' | translate}}
              </th>
              <th>
                {{'GLOBAL.PRICE' | translate}}
              </th>
              <th>
                {{'STORAGE.PRICE_WITH_DISCOUNT' | translate}}
              </th>
              <th>
                {{'GLOBAL.ACTION' | translate}}
              </th>
            </thead>
            <ng-container
              *ngFor="let object of peService.arrayAllTechCards | searchPipe: {search: peService.inputSearch, searchBy: 'name'}; let i = index">
              <tr scope="row">
                <td>
                  {{object.name}}
                </td>
                <td>
                  <label *ngIf=" object.showInput" class="option-label">
                    <input type="checkbox" class="option-input" (change)="toggleInput(object)">
                    <span class="checkmark tick">✓</span>
                  </label>
                  <label *ngIf="!object.showInput" class="option-label" (click)="disableInput(object)">
                    <input type="checkbox" class="option-input">
                    <div class="checkmark crisscross">✖</div>
                  </label>
                  <span *ngIf="object.showInput" class="add-price-text">{{'STORAGE.TURN_ON' | translate}}</span>
                  <span *ngIf="!object.showInput" class="add-price-text">{{'STORAGE.TURN_OFF' | translate}}</span>
                </td>

                <td>
                  <input type="number" class="form-control" [(ngModel)]="object.regular_price" [value]="object.regular_price">
                </td>

                <td>
                    <input type="number" [disabled]="object.showInput"
                            class="form-control placeholder-color"
                            [(ngModel)]="object.price"
                            [value]="object.price">
                </td>
                <td>
                  <button class="btn btn-primary" (click)="updatePriceDataBase(object, i);">{{'GLOBAL.SEND' |
                    translate}}</button>
                </td>
              </tr>
            </ng-container>
          </table>

        </ng-container>
      </ng-container>
    </div>
  </div>


  <ng-template #loading>
    <div class="col-sm-9">
      <div class="d-flex justify-content-center">
        <div class="spinner-grow text-warning" role="status" style="margin-left: .5rem">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-info" role="status" style="margin-left: .5rem">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning" role="status" style="margin-left: .5rem">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-info" role="status" style="margin-left: .5rem">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning" role="status" style="margin-left: .5rem">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </ng-template>

</div>

<table class="d-none" id="reportPrice">
  <thead>
    <tr>
      <th>ID</th>
      <th>{{'TECH_CARDS.CATEGORIES' | translate}}</th>
      <th>{{'TECH_CARDS.NAME' | translate}}</th>
      <th>{{'TECH_CARDS.PRICE' | translate}}</th>
      <th>{{'TECH_CARDS.CITY' | translate}}</th>
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let product of peService.arrayAllTechCards">
    <td>{{product.id}}</td>
    <td>{{product.cat_name}}</td>
    <td>{{product.name}}</td>
    <td>{{product.price}} {{currency}}</td>
    <td>
      <span *ngIf="product.city == 1">{{'TECH_CARDS.1' | translate}}</span>
      <span *ngIf="product.city == 2">{{'TECH_CARDS.2' | translate}}</span>
      <span *ngIf="product.city == 3">{{'TECH_CARDS.3' | translate}}</span>
      <span *ngIf="product.city == 8">{{'TECH_CARDS.8' | translate}}</span>
      <span *ngIf="product.city == 9">{{'TECH_CARDS.9' | translate}}</span>
      <span *ngIf="product.city == 10">{{'TECH_CARDS.10' | translate}}</span>
      <span *ngIf="product.city == 11">{{'TECH_CARDS.11' | translate}}</span>
      <span *ngIf="product.city == 12">{{'TECH_CARDS.12' | translate}}</span>
    </td>
  </tr>
  </tbody>
</table>



<ng-template #modal let-modal>
  <div class="modal-header">
    <h2 class="modal-title font-weight-bold" id="modal-basic-title">{{'TECH_CARDS.PUT_A_NEW_PRODUCT_ON_SALE' |
      translate}}</h2>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span>{{'TECH_CARDS.CHOOSE_A_PRODUCT' | translate}}</span>
    <select [(ngModel)]="selectedId" class="form-control my-2" (change)="checkFormValidity()">
      <option [value]="null" disabled selected>{{'PAYROLL.CHOOSE_NAME' | translate}}</option>
      <ng-container *ngFor="let obj of pendingPrice">
        <option [value]="obj.id">{{obj.crm_name.name}}</option>
      </ng-container>
    </select>

    <span class="mb-2">{{'TECH_CARDS.ENTER_PRICE' | translate}}</span>
    <input [(ngModel)]="inputValue" type="number" class="form-control mb-2" (input)="checkFormValidity()">
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-success" (click)="addIngredients()" [disabled]="!isFormValid">{{'PAYROLL.CREATE' | translate}}</button>
  </div>

</ng-template>