import {
  Component, EventEmitter,
  Input, Output
} from "@angular/core";
import {FormControl} from "@angular/forms";





@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent {

  @Input() source: string;
  @Input() imageSrc: string;
  @Input() controlName: string;
  @Input() formControl: FormControl;
  @Output() fileChanged = new EventEmitter<File>();

  fileName: string;
  imageInput: string;

  handleFileInput(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];

    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageInput = e.target.result;
      this.fileName = file.name;
      this.fileChanged.emit(file);
      console.log(this.imageInput);
      if (this.formControl) {
        this.formControl.setValue(this.imageInput);
      }
    };

    reader.readAsDataURL(file);
  }
}
