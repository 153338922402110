<div class="form-group col-sm-6 d-flex justify-content-center align-items-center flex-column">
    <span class="font-bold my-2">{{ 'GLOBAL.UPLOAD_AN_IMAGE' | translate }}</span>
    <div class="file-upload flex align-items-center">
        <input type="file" class="d-none"
               (change)="handleFileInput($event)" #fileUpload [formControl]="formControl" accept="image/webp">
        <ng-container *ngIf="fileName">
            <img #image class="mx-2" [src]="imageInput" alt="img" width="75vh" height="75vh">
            {{fileName }}
        </ng-container>
        <img *ngIf="source === 'main' && !fileName" #image class="mx-2" [src]="imageSrc" alt="img" width="60vh" height="60vh">
        <button class="mx-2 btn btn-info " (click)="fileUpload.click()">
            <img width="20vh" height="20vh" src="././assets/img/download-arrow.png">
        </button>
    </div>
</div>