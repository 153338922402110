    <div class="col-sm-4 mb-3">
        <select class="form-control" [formControl]="formControl">
            <option [value]="null" disabled selected>
                Виберіть мову:</option>
            <ng-container *ngFor="let obj of getFilteredLanguages(index);">
                <option [value]="obj.code">
                    {{ obj.name }}
                </option>
            </ng-container>
        </select>
    </div>
